import Swal from "sweetalert2";

const getListValueIdByFunctionalityId = (data) => {
    const options = {
        '60ace7db-6428-4c0c-9640-5f29f0d70f91': { id_lista_valor: '48fc2197-1ba2-4f31-9a3d-35a5c94cc488', condicao_adicional: '' },                   //Liberar Permissões
        '2a0bcc35-4452-4edb-b5fe-c92eba2f721c': { id_lista_valor: '7cb25a18-5edc-423b-881b-96169b5dcdec', condicao_adicional: '' },                   //Liberar Empresas
        '6c85577f-7d12-4083-9566-1466e59f3d1b': { id_lista_valor: '1204a8fd-2034-4da0-9113-bde9849a6dfe', condicao_adicional: `id_chamado = ${data.registros?.[0].id_chamado}` }, //Remover Participantes
        'c36a3b5c-946e-4ea9-9032-a2655ab8abc4': {
            id_lista_valor: 'dc0bbfee-3d77-4dd1-957b-ad5f55eb086c',
            condicao_adicional: ''
        },                                                                                                                                          //Adicionar Participantes
        '4fef3e70-5ec0-4347-a1ed-f00dfff05630': {
            id_lista_valor: '57f68c3b-0a0b-49fa-9b8b-f6152ccb35b0',
            condicao_adicional: `vw_departamento_usuario.id_departamento = (select c.id_departamento from chamado c where c.id_chamado = ${data.registros?.[0].id_chamado})`
        },                                                                                                                                          //Adicionar / Alterar Responsável
        'f1bad18b-eccc-4d91-a462-4bfb75e0f9e3': {
            id_lista_valor: '8c4fca2e-0653-4bac-aba9-c542009c7c85',
        },                                                                                                                                           //Transferir Chamado
        '93049f4d-ea1c-49b5-aac2-ccf973e45629': function(){
            if(data.id_tabela === '4b0fe29c-58fa-4b15-844c-fe2762a18020') {
                return { id_lista_valor: '8d1e25af-c52c-4038-9244-94d6e31bb3a2' }
            }
            if(data.id_tabela === 'e8f03ced-d35e-bbdd-c318-4c0cabdccebe') {
                return { id_lista_valor: '05faa3d8-003c-4ed0-931a-9673dcf964b7' }
            }
        }(),                                                                                                                                          //Replicar Configuração - departamento/categoria - conta financeira
        'dc928673-e5e6-481d-8e1b-eac550b57bdf': { id_lista_valor: 'da9fd874-252f-4195-b343-6280f468bdcb' },                                          //Incluir itens
        '3fd198d6-43e4-4a13-8def-873718d2138c': { id_lista_valor: '4b6028f8-375b-4a73-b54a-69d6d6e28fbd' },                                          //Setar Fornecedor
        'ed684b56-30f6-440b-8cfa-23c2b4f492a7': { id_lista_valor: 'd2431aa6-2e6a-4270-b818-a30575cf4005' },                                          //Mesclar chamado
        '16d74592-d6af-4f71-b81f-180861924aff': { id_lista_valor: '010b85fc-33a4-444e-aca6-05e33a35b0bb' },                                          //Configurar Categoria
    }

    const list = options[data.id_funcionalidade] || null
    if (!list) {
        return Swal.fire({
            icon: "error",
            text: `Funcionalidade com id [${data.id_funcionalidade}] não possui lista-valor associada`,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
        });
    }
    return list
}

export {
    getListValueIdByFunctionalityId
}